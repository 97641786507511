import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

// Fetch arts data
const fetchArts = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arts`);
    return data;
  } catch (error) {
    console.error("Error fetching arts:", error);
    throw new Error("Failed to fetch arts");
  }
};

// Updated RedirectButton Component
const RedirectArts = () => {
  const navigate = useNavigate();

  const { data: arts, isLoading, isError } = useQuery("arts", fetchArts);

  if (isLoading) return <p>Loading arts...</p>;
  if (isError) return <p>Failed to load arts.</p>;

  // Limit to 1-2 arts
  const previewArts = arts.slice(0, 2);

  return (
    <div className="arts-preview text-center">
      <h2 className="mb-3">Preview Paintings</h2>
      <div className="d-flex justify-content-center flex-wrap" style={{ gap: "20px" }}>
        {previewArts.map((art) => (
          <div key={art.id} className="art-item text-center">
            <img
              src={`${process.env.REACT_APP_API_URL}${art.image}`}
              alt={art.title}
              className="img-fluid mb-2"
              style={{ width: "150px", height: "auto", objectFit: "cover" }}
            />
            <h5>{art.title}</h5>
            <p><strong>Price:</strong> ${art.price}</p>
          </div>
        ))}
      </div>
      <button
        className="btn btn-primary mt-4"
        onClick={() => navigate("/arts")}
      >
        View All Paintings
      </button>
    </div>
  );
};

// BookPreview Component
const BookPreview = () => {
  const book = {
    id: 1,
    title: "Blooms From The Soul",
    description: "A captivating blend of art and words, inspired by the serene beauty of Lake Lucerne and the Swiss Alps. This book is a journey into creativity and self-expression, perfect for dreamers and art lovers alike.",
    price: 19.99,
    coverImage: "assets/chapters/1.jpeg",
  };

  const handlePurchase = () => {
    alert(`Purchasing book: ${book.title}`);
    // Add purchase logic here
  };

  return (
    <div className="book-preview text-center" style={{ width: "50%" }}>
      <h2 className="mb-3">Book Preview</h2>
      <img
        src={book.coverImage}
        alt={book.title}
        className="img-fluid mb-3"
        style={{ width: "150px", height: "auto" }}
      />
      <h3>{book.title}</h3>
      <p>{book.description}</p>
      <p>
        <strong>Price:</strong> ${book.price}
      </p>
      <button className="btn btn-success" onClick={handlePurchase}>
        Purchase
      </button>
    </div>
  );
};

const Achievev3 = ({ isBg }) => {
  return (
    <section
      id="arts"
      className={`achieve3 section-padding ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div
          className="d-flex justify-content-around align-items-start"
          style={{ gap: "20px" }}
        >
          {/* Book Preview */}
          <BookPreview />

          {/* Arts Preview with View All Button */}
          <RedirectArts />
        </div>

      </div>
    </section>
  );
};

export default Achievev3;
