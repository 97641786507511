/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { FaRegCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const fetchArts = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arts`);
  return data;
};

const AllEvents = ({ isBg }) => {
  const { data: arts, isLoading, isError } = useQuery("arts", fetchArts);

  if (isLoading) return <p>Loading arts...</p>;
  if (isError) return <p>Error fetching arts.</p>;

  return (
    <div className={`section-padding events ${isBg === "yes" ? "bg-one" : ""}`}>
      <div className="container">
        <div className="row">
          {Array.isArray(arts) && arts.length > 0 ? (
            arts.map((art, i) => (
              <div
                key={i}
                className="col-md-6 col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(i + 2) * 50}
              >
                <article className="events__single-event h-100 translateEffect1">
                  <div className="events__single-event__image">
                    <Link to={`/arts/${art.id}`}>
                      <img
                        className="img-fluid"
                        src={`${process.env.REACT_APP_API_URL}${art.image}`}
                        alt={art.title}
                      />
                    </Link>
                  </div>
                  <div className="events__single-event__body">
                    <div className="events__single-event__content">
                      <h2 className="fs-4">
                        <Link to={`/arts/${art.id}`}>{art.title}</Link>
                      </h2>
                      <p className="m-0">{art.description}</p>
                    </div>
                    <div className="events__single-event__meta">
                      <div>
                        <FaRegCalendarAlt /> Created At: {new Date(art.created_at).toLocaleDateString()}
                      </div>
                      <div>
                        <FaMapMarkerAlt /> Price: ${art.price}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            ))
          ) : (
            <p>No arts available.</p>
          )}
        </div>
        <div className="blog__pagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination">

              <li className="page-item">
                <Link className="page-link" to="/#">
                  Back to Home
                </Link>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
