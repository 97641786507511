import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

// Fetch single art data by ID
const fetchArtById = async (id) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arts/${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching art:", error);
    throw new Error("Failed to fetch art");
  }
};

const SingleEvents = () => {
  const { id } = useParams();

  const { data: art, isLoading, isError } = useQuery(["art", id], () => fetchArtById(id));

  if (isLoading) return <p>Loading art details...</p>;
  if (isError) return <p>Failed to load art details.</p>;

  return (
    <section className="section-padding single-event">
      <div className="container">
        <div className="row">
          <div
            className="col-md-7 col-lg-8 col-xl-9 mb-4 mb-md-0"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <div className="single-event__area">
              <div className="single-event__area__image">
                <img
                  className="img-fluid"
                  src={`${process.env.REACT_APP_API_URL}${art.image}`}
                  alt={art.title}
                />
              </div>
              <div className="single-event__area__menu">
                <h2 className="fs-2">{art.title}</h2>
                <p>{art.description}</p>
              </div>
            </div>
          </div>
          <div
            id="right"
            className="col-md-5 col-lg-4 col-xl-3"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <div className="single-event__sidebar">
              <h3 className="item-border text-center">Art Details</h3>
              <div className="single-event__sidebar__details item-border">
                <ul>
                  <li>
                    <i className="icofont-calendar"></i>
                    <div>
                      <p className="para-1">Date</p>
                      <p className="para-2">{art.created_at}</p>
                    </div>
                  </li>
                  <li>
                    <i className="icofont-user-alt-3"></i>
                    <div>
                      <p className="para-1">Artist</p>
                      <p className="para-2">{art.artist || "Unknown"}</p>
                    </div>
                  </li>
                  <li>
                    <i className="icofont-price"></i>
                    <div>
                      <p className="para-1">Price</p>
                      <p className="para-2">${art.price}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <a className="button button__primary">
                  <span>Purchase</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleEvents;
